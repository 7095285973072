<template>
  <div>
    <div class="relative bg-yellow-100 bg-opacity-50">
      <img class="sm:hidden w-full" src="/img/mabout.png" alt="Ask Us" />
      <div
        class="absolute inset-0 flex sm:items-center mt-4 sm:mt-0 justify-center"
      >
        <h2 class="font-bold text-3xl text-primary-orange">About Us</h2>
      </div>
      <img class="hidden sm:block w-full" src="/img/about.png" alt="Ask Us" />
    </div>
    <div class="px-6 md:px-12 md:max-w-4xl md:mx-auto">
      <p class="pt-12">
        "Change" This word has inspired our family of three so much that we wanted to give 
        a platform where people can come and know their rights
      </p>
    </div>
    <div
      class="mt-12 py-12 md:max-w-4xl md:mx-auto bg-yellow-100 bg-opacity-50"
    >
      <h2 class="text-3xl font-semibold text-primary-orange text-center">
        Wanted to empower people with their rights!
      </h2>
      <div class="mt-6 pt-4 w-48 mx-auto border-t border-primary-orange"></div>
      <p class="text-center text-sm">Browse other topic to know more.</p>
      <div class="mt-6 grid grid-cols-4 gap-8">
        <div
          v-for="i in 4"
          :key="'card-' + i"
          class="col-span-2 md:col-span-1 flex flex-col items-center justify-center space-y-1"
        >
          <div
            class="h-24 w-24 bg-white rounded-full border transition ease-in duration-150"
          ></div>
          <p class="text-4xl text-primary-orange font-semibold">9999</p>
          <p class="text-center">Events</p>
        </div>
      </div>
    </div>

    <div class="py-12 px-6 md:px-0 md:max-w-2xl md:mx-auto">
      <h2 class="text-3xl font-semibold text-primary-orange text-center">
        
      </h2>
      <div class="mt-5 pt-4 w-48 mx-auto border-t border-primary-orange"></div>
      <p class="text-center text-sm">
        Stay up to date and follow our latest news.
      </p>
      <div class="mt-6 md:max-w-md md:mx-auto">
        <label for="email" class="sr-only">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email Address"
          class="w-full px-4 py-3 border rounded-lg shadow"
        />
        <Button class="mt-6 w-full">Subscribe</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import MultiSearch from "@/components/MultiSearch";
import SocialLinks from "@/components/SocialLinks";

export default {
  components: {
    Button,
    MultiSearch,
    SocialLinks,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style></style>
